import request from '@/utils/request'

export async function getCaseList(paramData) {
  if (paramData?.active !== undefined) {
    return request({
      url: `/subjects?page=${paramData?.page}&limit=${paramData?.limit}&active=${paramData?.active}`,
      method: 'get',
    })
  } else {
    return request({
      url: `/subjects?page=${paramData?.page}&limit=${paramData?.limit}`,
      method: 'get',
    })
  }
}

export async function getCaseListByName(paramData) {
  if (paramData?.active !== undefined) {
    return request({
      url: `/subjects/name/${paramData?.name}?page=${paramData?.page}&limit=${paramData?.limit}&active=${paramData?.active}`,
      method: 'get',
    })
  } else {
    return request({
      url: `/subjects/name/${paramData?.name}?page=${paramData?.page}&limit=${paramData?.limit}`,
      method: 'get',
    })
  }
}

export async function getCaseListByGroup(paramData) {
  if (paramData?.active !== undefined) {
    return request({
      url: `/subjects/group/${paramData?.group}?page=${paramData?.page}&limit=${paramData?.limit}&active=${paramData?.active}`,
      method: 'get',
    })
  } else {
    return request({
      url: `/subjects/group/${paramData?.group}?page=${paramData?.page}&limit=${paramData?.limit}`,
      method: 'get',
    })
  }
}

export async function setMultipleGroupToCase(postData) {
  return request({
    url: `/affiliate/idt/${postData?.idType}/idv/${postData?.idValue}/groups`,
    method: 'post',
    data: {
      groupIds: postData?.groupIds,
    },
  })
}

export async function deleteMultipleGroupToCase(postData) {
  return request({
    url: `/affiliate/idt/${postData?.idType}/idv/${postData?.idValue}/groups`,
    method: 'delete',
    data: {
      groupIds: postData?.groupIds,
    },
  })
}

export async function modifyCaseInfo(data) {
  return request({
    url: '/subjects',
    method: 'patch',
    data,
  })
}

export async function createCase(data) {
  return request({
    url: '/subjects',
    method: 'post',
    data,
  })
}

export async function getCase(fid) {
  return request({
    url: '/subjects/fid/' + fid,
    method: 'get',
  })
}

export async function deleteCase(fid) {
  return request({
    url: '/subjects/fid/' + fid,
    method: 'delete',
  })
}
