import {
  getCaseList,
  getCaseListByName,
  getCaseListByGroup,
  modifyCaseInfo,
  createCase,
  getCase,
  deleteCase,
  setMultipleGroupToCase,
  deleteMultipleGroupToCase,
} from '@/api/case'

const state = () => ({
  cases: [],
  currentCase: {},
  filterFlag: 'Activate',
})

const getters = {
  cases: (state) => state.cases,
  caseTotal: (state) => state.caseTotal,
  currentCase: (state) => state.currentCase,
  filterFlag: (state) => state.filterFlag,
}

const mutations = {
  setFilterFlag(state, filterFlag) {
    state.filterFlag = filterFlag
    localStorage.setItem('storeCase', JSON.stringify(state))
  },
  setCase(state, cases) {
    state.cases = cases
    localStorage.setItem('storeCase', JSON.stringify(state))
  },
  setCaseTotal(state, caseTotal) {
    state.caseTotal = caseTotal
    localStorage.setItem('storeCase', JSON.stringify(state))
  },
  setCurrentCase(state, currentCase) {
    state.currentCase = currentCase
    localStorage.setItem('storeCase', JSON.stringify(state))
  },
}

const actions = {
  async getCaseList({ state, commit }, paramData) {
    try {
      // ['Activate', 'Suspended', 'All']
      if (paramData.filterFlag === undefined) {
        paramData.filterFlag = state.filterFlag
      }
      if (paramData.filterFlag === 'Activate') {
        paramData.active = 1
        commit('setFilterFlag', 'Activate')
      } else if (paramData.filterFlag === 'Suspended') {
        paramData.active = 0
        commit('setFilterFlag', 'Suspended')
      } else {
        commit('setFilterFlag', 'All')
      }
      const data = await getCaseList(paramData)
      if (data.ok === true && data.subjects) {
        commit('setCaseTotal', data.total ? data.total : 0)
        commit('setCase', data.subjects)
      } else {
        commit('setCaseTotal', 0)
        commit('setCase', [])
      }
      return data
      // if (data.ok === true && data.subjects) {
      //   if (paramData.filterFlag === 'Activate') {
      //     data.subjects = data.subjects.filter(
      //       (item) => item.state === 'ACTIVATED'
      //     )
      //     commit('setFilterFlag', 'Activate')
      //   } else if (paramData.filterFlag === 'Suspended') {
      //     data.subjects = data.subjects.filter(
      //       (item) => item.state === 'SUSPENDED'
      //     )
      //     commit('setFilterFlag', 'Suspended')
      //   } else {
      //     // All
      //     data.subjects.sort((a, b) => {
      //       if (a.state === 'ACTIVATED' && b.state !== 'ACTIVATED') {
      //         return -1
      //       }
      //       if (a.state !== 'ACTIVATED' && b.state === 'ACTIVATED') {
      //         return 1
      //       }
      //       return 0
      //     })
      //     commit('setFilterFlag', 'All')
      //   }

      //   commit('setCase', data.subjects)
      // } else {
      //   commit('setCase', [])
      // }
    } catch (e) {
      commit('setCase', [])
      console.log('exception')
      console.log(e)
    }
  },
  async getCaseListByName({ state, commit }, paramData) {
    try {
      // ['Activate', 'Suspended', 'All']
      if (paramData.filterFlag === undefined) {
        paramData.filterFlag = state.filterFlag
      }
      if (paramData.filterFlag === 'Activate') {
        paramData.active = 1
        commit('setFilterFlag', 'Activate')
      } else if (paramData.filterFlag === 'Suspended') {
        paramData.active = 0
        commit('setFilterFlag', 'Suspended')
      } else {
        commit('setFilterFlag', 'All')
      }
      const data = await getCaseListByName(paramData)
      if (data.ok === true && data.subjects) {
        commit('setCaseTotal', data.total ? data.total : 0)
        commit('setCase', data.subjects)
      } else {
        commit('setCaseTotal', 0)
        commit('setCase', [])
      }
      return data
    } catch (e) {
      commit('setCase', [])
      console.log('exception')
      console.log(e)
    }
  },
  async getCaseListByGroup({ state, commit }, paramData) {
    try {
      // ['Activate', 'Suspended', 'All']
      if (paramData.filterFlag === undefined) {
        paramData.filterFlag = state.filterFlag
      }
      if (paramData.filterFlag === 'Activate') {
        paramData.active = 1
        commit('setFilterFlag', 'Activate')
      } else if (paramData.filterFlag === 'Suspended') {
        paramData.active = 0
        commit('setFilterFlag', 'Suspended')
      } else {
        commit('setFilterFlag', 'All')
      }
      const data = await getCaseListByGroup(paramData)
      if (data.ok === true && data.subjects) {
        commit('setCaseTotal', data.total ? data.total : 0)
        commit('setCase', data.subjects)
      } else {
        commit('setCaseTotal', 0)
        commit('setCase', [])
      }
      return data
    } catch (e) {
      commit('setCase', [])
      console.log('exception')
      console.log(e)
    }
  },
  async modifyCaseInfo({ dispatch }, postBody) {
    try {
      const data = await modifyCaseInfo(postBody)
      // if (data.ok === true) {
      //   dispatch('getCaseList')
      // }
      return data
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async createCase({ dispatch }, postBody) {
    try {
      const data = await createCase(postBody)
      return data
      // if (data.ok === true) {
      //   dispatch('getCaseList')
      // }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async setMultipleGroupToCase({ dispatch }, postBody) {
    try {
      const data = await setMultipleGroupToCase(postBody)
      return data
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async deleteMultipleGroupToCase({ dispatch }, postBody) {
    try {
      const data = await deleteMultipleGroupToCase(postBody)
      return data
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  clearCurrentCaseData({ commit }) {
    try {
      commit('setCurrentCase', {})
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async getCase({ commit }, fid) {
    try {
      const data = await getCase(fid)
      if (data.ok === true && data.result) {
        commit('setCurrentCase', data.result)
      } else {
        commit('setCurrentCase', {})
      }
    } catch (e) {
      commit('setCurrentCase', {})
      console.log('exception')
      console.log(e)
    }
  },
  async deleteCase({ dispatch }, fid) {
    try {
      const data = await deleteCase(fid)
      if (data.ok === true) {
        dispatch('getCaseList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async clearCaseData({ commit }) {
    try {
      commit('setCase', [])
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
}

export default { state, getters, mutations, actions }
